import React, { ReactElement, forwardRef } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import HomeIcon from '@mui/icons-material/Home'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import SearchIcon from '@mui/icons-material/Search'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import PhoneIcon from '@mui/icons-material/Phone'
import MailIcon from '@mui/icons-material/MailOutlined'

import Alert from '../../../../static/img/svgIcons/alert.svg'
import ArrowDown from '../../../../static/img/svgIcons/arrow_down.svg'
import ArrowRight from '../../../../static/img/svgIcons/arrow_right.svg'
import ArrowLeft from '../../../../static/img/svgIcons/arrow_left.svg'
import ArrowScrollDown from '../../../../static/img/svgIcons/arrow_scroll_down.svg'
import ArrowTeaser from '../../../../static/img/svgIcons/arrow_teaser.svg'
import ArrowUp from '../../../../static/img/svgIcons/arrow_up.svg'
import LessSummary from '../../../../static/img/svgIcons/less-summary.svg'
import MoreSummary from '../../../../static/img/svgIcons/more-summary.svg'
import More from '../../../../static/img/svgIcons/more.svg'
import LinkArrowRight from '../../../../static/img/svgIcons/arrow_link_right.svg'
import ChartCheck from '../../../../static/img/svgIcons/chart_check.svg'
import ChartNoCheck from '../../../../static/img/svgIcons/chart_nocheck.svg'
import Close from '../../../../static/img/svgIcons/close.svg'
import DBN from '../../../../static/img/svgIcons/dbn.svg'
import DBNN from '../../../../static/img/svgIcons/dbn-n.svg'
import DBNLogoOuter from '../../../../static/img/svgIcons/DBN-Logo_outer.svg'
import DBNLogoInner from '../../../../static/img/svgIcons/DBN-Logo_inner.svg'
import Descale from '../../../../static/img/svgIcons/descale.svg'
import Download from '../../../../static/img/svgIcons/download.svg'
import DownloadColored from '../../../../static/img/svgIcons/download_colored.svg'
import Duration from '../../../../static/img/svgIcons/dauer.svg'
import EasyLang from '../../../../static/img/svgIcons/easy_lang.svg'
import Facebook from '../../../../static/img/svgIcons/facebook.svg'
import IlluBeginner from '../../../../static/img/svgIcons/illu_anfaenger.svg'
import IlluBike from '../../../../static/img/svgIcons/illu_fahrrad.svg'
import IlluCar from '../../../../static/img/svgIcons/illu_pkw.svg'
import IlluChildren from '../../../../static/img/svgIcons/illu_kinder.svg'
import IlluCommercial from '../../../../static/img/svgIcons/illu_berufskraftfahrer.svg'
import IlluMotorcycle from '../../../../static/img/svgIcons/illu_motorrad.svg'
import IlluPedestrian from '../../../../static/img/svgIcons/illu_fussgaenger.svg'
import IlluSeniors from '../../../../static/img/svgIcons/illu_senioren.svg'
import Instagram from '../../../../static/img/svgIcons/instagram.svg'
import Linkedin from '../../../../static/img/svgIcons/linkedin.svg'
import Layers from '../../../../static/img/svgIcons/layers.svg'
import LockIcon from '../../../../static/img/svgIcons/lock.svg'
import Menu from '../../../../static/img/svgIcons/menu.svg'
import PaginationPrev from '../../../../static/img/svgIcons/pagination_prev.svg'
import PaginationNext from '../../../../static/img/svgIcons/pagination_next.svg'
import Quote from '../../../../static/img/svgIcons/quote.svg'
import Scale from '../../../../static/img/svgIcons/scale.svg'
import SearchEmpty from '../../../../static/img/svgIcons/search_empty.svg'
import SfFamily from '../../../../static/img/svgIcons/sf_family.svg'
import StagePause from '../../../../static/img/svgIcons/button_stage_pause.svg'
import StagePlay from '../../../../static/img/svgIcons/button_stage_play.svg'
import Twitter from '../../../../static/img/svgIcons/twitter.svg'
import ShareXING from '../../../../static/img/svgIcons/share_xing.svg'
import ShareLinkedIn from '../../../../static/img/svgIcons/share_linkedin.svg'
import ShareFacebook from '../../../../static/img/svgIcons/share_facebook.svg'
import ShareTwitter from '../../../../static/img/svgIcons/share_twitter.svg'
import ShareEMail from '../../../../static/img/svgIcons/share_email.svg'
import Spotify from '../../../../static/img/svgIcons/spotify.svg'
import YouTubePlay from '../../../../static/img/svgIcons/youtube_play.svg'

export type IconProps = {
  className?: string
  name: string
  size?: 'inherit' | 'default' | 'small' | 'large'
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'action'
    | 'disabled'
    | 'error'
    | 'white'
    | 'black'
}

const Icon = forwardRef(function Icon(
  { className, name, size, color }: IconProps,
  ref,
): ReactElement | null {
  function renderIcon() {
    const svgProps: SvgIconProps = {
      fontSize: size,
      color: color,
      className: className,
    }
    const illuProps: SvgIconProps = {
      ...svgProps,
      viewBox: '0 0 64 64',
      style: { fontSize: 64 },
    }
    switch (name) {
      case 'Alert':
        return (
          <SvgIcon {...illuProps}>
            <Alert />
          </SvgIcon>
        )
      case 'ArrowDown':
        return (
          <SvgIcon {...svgProps}>
            <ArrowDown />
          </SvgIcon>
        )
      case 'ArrowUp':
        return (
          <SvgIcon {...svgProps}>
            <ArrowUp />
          </SvgIcon>
        )
      case 'LessSummary':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 48 48' }}>
            <LessSummary />
          </SvgIcon>
        )
      case 'MoreSummary':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 48 48' }}>
            <MoreSummary />
          </SvgIcon>
        )
      case 'More':
        return (
          <SvgIcon {...svgProps}>
            <More />
          </SvgIcon>
        )
      case 'ArrowRight':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 40 28' }}>
            <ArrowRight />
          </SvgIcon>
        )
      case 'ArrowLeft':
        return (
          <SvgIcon {...svgProps}>
            <ArrowLeft />
          </SvgIcon>
        )
      case 'ArrowScrollDown':
        return (
          <SvgIcon {...svgProps}>
            <ArrowScrollDown />
          </SvgIcon>
        )
      case 'LinkArrowRight':
        return (
          <SvgIcon {...svgProps}>
            <LinkArrowRight />
          </SvgIcon>
        )
      case 'ArrowTeaser':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 16 16' }}>
            <ArrowTeaser />
          </SvgIcon>
        )
      case 'Lock':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <LockIcon />
          </SvgIcon>
        )
      case 'Basket':
        return <ShoppingBasketIcon {...svgProps} />
      case 'ChevronDown':
        return <ExpandMoreIcon {...svgProps} />
      case 'ChevronLeft':
        return <ChevronLeftIcon {...svgProps} />
      case 'ChevronRight':
        return <ChevronRightIcon {...svgProps} />
      case 'ChevronUp':
        return <ExpandLessIcon {...svgProps} />
      case 'Close':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <Close />
          </SvgIcon>
        )
      case 'DBN':
        return (
          <SvgIcon {...svgProps} viewBox="0 0 715.09 158.57">
            <DBN />
          </SvgIcon>
        )
      case 'DBNN':
        return (
          <SvgIcon {...svgProps} viewBox="0 0 42 43">
            <DBNN />
          </SvgIcon>
        )
      case 'DBNLogoInner':
        return (
          <SvgIcon {...svgProps} viewBox="-150 -136.5 520 520">
            <DBNLogoInner />
          </SvgIcon>
        )
      case 'DBNLogoOuter':
        return (
          <SvgIcon {...svgProps} ref={ref} viewBox="0 0 520 520">
            <DBNLogoOuter />
          </SvgIcon>
        )
      case 'Description':
        return <DescriptionOutlinedIcon {...svgProps} />
      case 'Download':
        return (
          <SvgIcon {...svgProps}>
            <Download />
          </SvgIcon>
        )
      case 'DownloadColored':
        return (
          <SvgIcon {...svgProps}>
            <DownloadColored />
          </SvgIcon>
        )
      case 'Duration':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 16 16' }}>
            <Duration />
          </SvgIcon>
        )
      case 'EasyLang':
        return (
          <SvgIcon {...svgProps}>
            <EasyLang />
          </SvgIcon>
        )
      case 'Facebook':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 32 32' }}>
            <Facebook />
          </SvgIcon>
        )
      case 'Home':
        return <HomeIcon {...svgProps} />
      case 'IlluBeginner':
        return (
          <SvgIcon {...illuProps}>
            <IlluBeginner />
          </SvgIcon>
        )
      case 'IlluBike':
        return (
          <SvgIcon {...illuProps}>
            <IlluBike />
          </SvgIcon>
        )
      case 'IlluCar':
        return (
          <SvgIcon {...illuProps}>
            <IlluCar />
          </SvgIcon>
        )
      case 'IlluChildren':
        return (
          <SvgIcon {...illuProps}>
            <IlluChildren />
          </SvgIcon>
        )
      case 'IlluCommercial':
        return (
          <SvgIcon {...illuProps}>
            <IlluCommercial />
          </SvgIcon>
        )
      case 'IlluMotorcycle':
        return (
          <SvgIcon {...illuProps}>
            <IlluMotorcycle />
          </SvgIcon>
        )
      case 'IlluPedestrian':
        return (
          <SvgIcon {...illuProps}>
            <IlluPedestrian />
          </SvgIcon>
        )
      case 'IlluSeniors':
        return (
          <SvgIcon {...illuProps}>
            <IlluSeniors />
          </SvgIcon>
        )
      case 'Layers':
        return (
          <SvgIcon {...svgProps}>
            <Layers />
          </SvgIcon>
        )
      case 'Mail':
        return <MailIcon {...svgProps} />
      case 'Menu':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <Menu />
          </SvgIcon>
        )
      case 'OpenInNew':
        return <OpenInNewIcon {...svgProps} />
      case 'PaginationPrev':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 16 16' }}>
            <PaginationPrev />
          </SvgIcon>
        )
      case 'PaginationNext':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 16 16' }}>
            <PaginationNext />
          </SvgIcon>
        )
      case 'Phone':
        return <PhoneIcon {...svgProps} />
      case 'Print':
        return <PrintOutlinedIcon {...svgProps} />
      case 'Quote':
        return (
          <SvgIcon
            {...{
              ...svgProps,
              viewBox: '0 0 84 24',
            }}
          >
            <Quote />
          </SvgIcon>
        )
      case 'Scale':
        return (
          <SvgIcon {...svgProps}>
            <Scale />
          </SvgIcon>
        )
      case 'Descale':
        return (
          <SvgIcon {...svgProps}>
            <Descale />
          </SvgIcon>
        )
      case 'Search':
        return <SearchIcon {...svgProps} />
      case 'SearchEmpty':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 222 134' }}>
            <SearchEmpty />
          </SvgIcon>
        )
      case 'Speech':
        return <ForumOutlinedIcon {...svgProps} />
      case 'Twitter':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <Twitter />
          </SvgIcon>
        )
      case 'Instagram':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <Instagram />
          </SvgIcon>
        )
      case 'LinkedIn':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <Linkedin />
          </SvgIcon>
        )
      case 'YouTubePlay':
        return (
          <SvgIcon
            {...{
              ...svgProps,
              viewBox: '0 0 68 49',
            }}
            style={{
              width: '68px',
              height: '49px',
            }}
          >
            <YouTubePlay {...svgProps} />
          </SvgIcon>
        )
      case 'StagePlay':
        return (
          <SvgIcon
            {...{ ...svgProps, viewBox: '0 0 54 54' }}
            style={{
              width: '54px',
              height: '54px',
            }}
          >
            <StagePlay />
          </SvgIcon>
        )
      case 'StagePause':
        return (
          <SvgIcon
            {...{ ...svgProps, viewBox: '0 0 54 54' }}
            style={{
              width: '54px',
              height: '54px',
            }}
          >
            <StagePause />
          </SvgIcon>
        )
      case 'ChartCheck':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ChartCheck />
          </SvgIcon>
        )
      case 'ChartNoCheck':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ChartNoCheck />
          </SvgIcon>
        )
      case 'SfFamily':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 124 132' }}>
            <SfFamily />
          </SvgIcon>
        )
      case 'shareXING':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ShareXING />
          </SvgIcon>
        )
      case 'shareLinkedIn':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ShareLinkedIn />
          </SvgIcon>
        )
      case 'shareFacebook':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ShareFacebook />
          </SvgIcon>
        )
      case 'shareTwitter':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ShareTwitter />
          </SvgIcon>
        )
      case 'shareEMail':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <ShareEMail />
          </SvgIcon>
        )
      case 'Spotify':
        return (
          <SvgIcon {...{ ...svgProps, viewBox: '0 0 24 24' }}>
            <Spotify />
          </SvgIcon>
        )
      default:
        return null
    }
  }
  return renderIcon()
})

export default Icon
