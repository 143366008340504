import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import { useLocation } from '@gatsbyjs/reach-router'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import clsx from 'clsx'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  curtain: `${PREFIX}-curtain`,
}

const StyledTransitionGroup = styled(TransitionGroup)(
  ({
    theme,
    x,
    y,
    radius,
  }: {
    theme: Theme
    x: number
    y: number
    radius: number
  }) => ({
    [`&.${classes.root}`]: {
      position: 'relative',
    },

    [`& .${classes.content}`]: {
      '&.enter': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        zIndex: -1000,
      },
      '&.enter-active': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        opacity: 0,
        zIndex: -1000,
      },
    },

    [`& .${classes.curtain}`]: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.button.mainHover,
      zIndex: 10000,
      pointerEvents: 'none',
      opacity: 1,
      transition: 'clip-path 550ms ease, opacity 550ms ease-in-out',
      display: 'none',
      '&.exit': {
        display: 'none',
      },
      '&.exit-active': {
        display: 'none',
      },
      '&.exit-done': {
        display: 'none',
      },
      '&.enter': {
        clipPath: `circle(0vw at ${x || 0}px ${y || 0}px)`,
        display: 'block',
      },
      '&.enter-active': {
        clipPath: `circle(${radius}px at ${x || 0}px ${y || 0}px)`,
        display: 'block',
      },
      '&.enter-done': {
        display: 'block',
        opacity: 0,
      },
    },
  }),
)

export const durationEnter = 550
export const durationExit = 550

export type PageTransitionPros = DBN.IReactDefaultProps

export default function PageTransition({
  children,
}: PageTransitionPros): ReactElement {
  const x =
    typeof document !== 'undefined'
      ? Number(
          document.documentElement.style.getPropertyValue('--x').slice(0, -2),
        )
      : 0
  const y =
    typeof document !== 'undefined'
      ? Number(
          document.documentElement.style.getPropertyValue('--y').slice(0, -2),
        )
      : 0
  const radius =
    typeof window !== 'undefined'
      ? Math.sqrt(
          Math.pow(Math.max(window.innerWidth - x, x), 2) +
            Math.pow(Math.max(window.innerHeight - y, y), 2),
        )
      : 5000

  const location = useLocation()

  return (
    <StyledTransitionGroup
      component="div"
      className={classes.root}
      x={x}
      y={y}
      radius={radius}
    >
      <CSSTransition
        key={location.pathname}
        timeout={{
          enter: durationEnter,
          exit: durationExit,
        }}
      >
        <div className={clsx(classes.content)}>{children}</div>
      </CSSTransition>
      <CSSTransition
        key={location.pathname + '-curtain-' + location.key}
        timeout={{
          enter: durationEnter,
          exit: durationExit,
        }}
        onEntered={() => window.dispatchEvent(new Event('resize'))}
      >
        <div className={clsx(classes.curtain)} />
      </CSSTransition>
    </StyledTransitionGroup>
  )
}
