import React, { ReactElement, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { globalHistory, useLocation } from '@gatsbyjs/reach-router'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'
import { defaultLang } from '@system/translations'

import { Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import useCustomCursor from '@system/hooks/useCustomCursor'

import useModuleTheme from '@system/hooks/useModuleTheme'
// import Link from '@components/core/link'
import Icon from '@components/core/icon'
import useGlobalText from '@system/hooks/useGlobalText'
import MenuHighlight from '../../../../../static/img/svgIcons/menu-highlight.svg'

const PREFIX = 'index'

const classes = {
  headerRoot: `${PREFIX}-headerRoot`,
  headerHide: `${PREFIX}-headerHide`,
  headerLink: `${PREFIX}-headerLink`,
  headerLogoImage: `${PREFIX}-headerLogoImage`,
  headerBurger: `${PREFIX}-headerBurger`,
  headerBurgerButton: `${PREFIX}-headerBurgerButton`,
  menu: `${PREFIX}-menu`,
  menuNav: `${PREFIX}-menuNav`,
  menuNavLink: `${PREFIX}-menuNavLink`,
  menuNavLinkIconContainer: `${PREFIX}-menuNavLinkIconContainer`,
  menuNavLinkIcon: `${PREFIX}-menuNavLinkIcon`,
  menuSubnav: `${PREFIX}-menuSubnav`,
  menuSubnavItems: `${PREFIX}-menuSubnavItems`,
  menuSubnavClose: `${PREFIX}-menuSubnavClose`,
  opening: `${PREFIX}-opening`,
  opened: `${PREFIX}-opened`,
  closing: `${PREFIX}-closing`,
  closed: `${PREFIX}-closed`,
}

const Root = styled('header')(({ theme }) => ({
  [`&.${classes.headerRoot}`]: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    left: '0px',
    paddingRight: 0,
    paddingLeft: 0,
    color: theme.palette.text.invert,
    background: 'transparent',
    transition: 'transform 0.55s ease, opacity 0.55s ease',
    zIndex: 50,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(12),
      paddingLeft: theme.spacing(12),
    },

    '&.dark': {
      color: theme.palette.text.invert,
    },
    '&.light': {
      color: theme.palette.text.primary,
    },
  },

  [`&.${classes.headerHide}`]: {
    [`&.${classes.headerHide}`]: {
      transform: 'translate(0, -20px)',
      opacity: 0,
      pointerEvents: 'none',
    },
  },

  [`& .${classes.headerLink}`]: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    transition: 'color 0.3s',

    '@media (hover: hover)': {
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        width: '49.5px',
        height: '49.5px',
        backgroundColor: '#FF2001',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%) scale(0)',
        transition: 'transform 0.3s',
      },
      '&:hover': {
        color: theme.palette.text.primary,

        '&::before': {
          transform: 'translate(-50%, -50%) scale(1)',
        },
      },
    },
  },

  [`& .${classes.headerLogoImage}`]: {
    position: 'relative',
    display: 'block',
    width: '42px',
    height: '42px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },

  [`& .${classes.headerBurger}`]: {},

  [`& .${classes.headerBurgerButton}`]: {
    position: 'relative',
    appearance: 'none',
    background: 'none',
    color: 'inherit',
    border: 'none',
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    zIndex: 2,
    width: '24px',
    height: '24px',
    transition: 'transform 0.3s',

    '& span': {
      position: 'absolute',
      display: 'block',
      width: '24px',
      height: '2px',
      backgroundColor: 'currentColor',
      top: '50%',
      left: '50%',
      margin: '-1px -12px',
      transition: 'background-color 0.3s',
      '&:first-child': {
        transform: 'translate(0, -4px)',
      },
      '&:last-child': {
        transform: 'translate(0, 4px)',
      },
    },

    '@media (hover: hover)': {
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        width: '27px',
        height: '27px',
        backgroundColor: '#FF2001',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%) scale(0)',
        transition: 'transform 0.3s',
      },
      '&:hover': {
        transform: 'scale(1.83333)',
        '& span': {
          backgroundColor: theme.palette.text.primary,
        },
        '&::before': {
          transform: 'translate(-50%, -50%) scale(1)',
        },
      },
    },
  },

  [`& .${classes.menu}`]: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'stretch',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    color: theme.palette.text.invert,
    backgroundColor: theme.palette.secondary.main,
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 1,
    clipPath: 'var(--clip-path)',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },

  [`& .${classes.menuNav}`]: {
    marginTop: theme.spacing(24),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(17),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 'unset',
      marginLeft: theme.spacing(27.5),
      marginRight: theme.spacing(27.5),
      width: 527,
    },
  },

  [`& .${classes.menuNavLink}`]: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: '54px',
    minHeight: '54px',
    background: 'none',
    border: 'none',
    padding: 0,
    fontSize: 40,
    fontWeight: 700,
    fontFamily: 'inherit',
    color: theme.palette.text.invert,
    [theme.breakpoints.up('md')]: {
      lineHeight: '96px',
      minHeight: '96px',
      fontSize: 80,
    },
    '& span': {
      transition: 'transform 0.3s ease-in-out',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: '50%',
      width: '16px',
      height: '16px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      transform: 'translateY(-50%) scale(0)',
      transition: 'transform 0.3s ease-in-out',
      [theme.breakpoints.up('md')]: {
        width: '24px',
        height: '24px',
      },
    },
    '&:hover': {
      '& span': {
        transform: 'translateX(32px)',
        [theme.breakpoints.up('md')]: {
          transform: 'translateX(40px)',
        },
      },
    },
    '&.active': {
      '&::before': {
        transform: 'translateY(-50%) scale(1)',
      },
      '& span': {
        transform: 'translateX(32px)',
        [theme.breakpoints.up('md')]: {
          transform: 'translateX(40px)',
        },
      },
    },
    '&.active:hover': {
      '& span': {
        transform: 'translateX(64px)',
        [theme.breakpoints.up('md')]: {
          transform: 'translateX(80px)',
        },
      },
    },
  },

  [`& .${classes.menuNavLinkIconContainer}`]: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    width: 32,
    [theme.breakpoints.up('md')]: {
      height: 48,
      width: 48,
    },
    '& svg': {
      width: 26.67,
      [theme.breakpoints.up('md')]: {
        width: 40,
      },
    },
    '&.back': {
      transform: 'rotate(0.5turn)',
      marginRight: theme.spacing(6),
    },
    '@media (hover: hover)': {
      '&::before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        display: 'block',
        top: '50%',
        left: '50%',
        width: '48px',
        height: '48px',
        backgroundColor: '#FF2001',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%) scale(0)',
        transition: 'transform 0.3s',
      },
      '&:hover': {
        [`& .${classes.menuNavLinkIcon}`]: {
          color: theme.palette.text.primary,
        },
        '&::before': {
          transform: 'translate(-50%, -50%) scale(1)',
        },
      },
    },
  },

  [`& .${classes.menuNavLinkIcon}`]: {
    height: 28,
    width: 40,
  },

  [`& .${classes.menuSubnav}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    clipPath: 'var(--clip-path)',
    transition: 'clip-path 550ms ease, background-color 550ms ease',
    '&.closed': {
      [theme.breakpoints.up('md')]: {
        backgroundColor: '#FF2001',
      },
    },
  },

  [`& .${classes.menuSubnavItems}`]: {
    position: 'absolute',
    top: theme.spacing(45.5),
    left: theme.spacing(8),
    right: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(10),
      height: '100%',
      top: 'unset',
      left: '35vw',
      right: '29vw',
      minWidth: '30rem',
    },
    [`& .${classes.menuNavLink}`]: {
      lineHeight: '28px',
      minHeight: '28px',
      fontSize: 24,
      [theme.breakpoints.up('md')]: {
        lineHeight: '48px',
        minHeight: '48px',
        fontSize: 36,
      },
      '&::before': {
        width: '8px',
        height: '8px',
        backgroundColor: theme.palette.text.invert,
      },
      '&:hover': {
        '& span': {
          transform: 'translateX(19px)',
          [theme.breakpoints.up('md')]: {
            transform: 'translateX(24px)',
          },
        },
      },
      '&.active': {
        '& span': {
          transform: 'translateX(19px)',
          [theme.breakpoints.up('md')]: {
            transform: 'translateX(24px)',
          },
        },
      },
      '&.active:hover': {
        '& span': {
          transform: 'translateX(38px)',
          [theme.breakpoints.up('md')]: {
            transform: 'translateX(48px)',
          },
        },
      },
      '&.mainPage .highlight': {
        position: 'absolute',
        zIndex: -1,
        color: theme.palette.secondary.main,
        transform: `translateX(${theme.spacing(-2)})`,
        [theme.breakpoints.up('md')]: {
          transform: `translateX(${theme.spacing(-12.5)})`,
        },
      },
    },
  },

  [`& .${classes.menuSubnavClose}`]: {
    zIndex: 100,
    position: 'absolute',
    top: theme.spacing(24),
    left: theme.spacing(5),
    transition: 'opacity 0.3s ease, transform 0.3s ease, visibility 0.3s',
    [theme.breakpoints.up('md')]: {
      top: 'unset',
      bottom: theme.spacing(10),
      left: theme.spacing(16),
    },
    '&.hide': {
      transform: 'translate(40px,0)',
      opacity: 0,
      pointerEvents: 'none',
      visibility: 'hidden',
    },
  },

  // langLink: {
  //   display: 'inline-block',
  //   ...theme.typography.navigationmenu,
  //   color: theme.palette.text.tertiary,
  //   textTransform: 'uppercase',
  //   '&:hover': {
  //     color: 'inherit',
  //   },
  //   marginLeft: theme.spacing(4),
  // },

  [`&.${classes.opening}`]: {
    [`& .${classes.headerBurgerButton}`]: {
      color: theme.palette.text.invert,
      transition: 'color 0.3s',
      transform: 'scale(1.33333)',
      '& span': {
        width: '26px',
        margin: '-1px -13px',
        transition: 'all 0.3s',
        '&:first-child': {
          transform: 'translate(0,0) rotate(45deg)',
        },
        '&:last-child': {
          transform: 'translate(0,0) rotate(-45deg)',
        },
      },
      [theme.breakpoints.up('md')]: {
        transform: 'scale(1.83333)',
      },
    },
    [`& .${classes.menu}`]: {
      backgroundColor: theme.palette.secondary.main,
      transition:
        'background-color 0.55s ease-in-out 0.275s, clip-path 550ms ease',
    },
    [`& .${classes.menuNav}`]: {
      transform: 'translate(0,0)',
      opacity: 1,
      transition: 'transform 0.55s ease-out 0.5s, opacity 0.55s ease-out 0.5s',
    },
  },

  [`&.${classes.opened}`]: {
    [`& .${classes.headerBurgerButton}`]: {
      color: theme.palette.text.invert,
      transform: 'scale(1.33333)',
      '& span': {
        width: '26px',
        margin: '-1px -13px',
        '&:first-child': {
          transform: 'translate(0,0) rotate(45deg)',
        },
        '&:last-child': {
          transform: 'translate(0,0) rotate(-45deg)',
        },
      },
      [theme.breakpoints.up('md')]: {
        transform: 'scale(1.83333)',
      },
    },
    [`& .${classes.menuNav}`]: {
      transform: 'translate(0,0)',
      opacity: 1,
      transition: 'opacity 0.3s ease, transform 0.3s ease',
      '&.hide': {
        transform: 'translate(-40px,0)',
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },

  [`&.${classes.closing}`]: {
    [`& .${classes.headerBurgerButton}`]: {
      transition: 'color 0.3s 0.3s',
      '& span': {
        transition: 'all 0.3s 0.3s',
      },
    },
    [`& .${classes.menu}`]: {
      transition: 'clip-path 550ms ease, background-color 550ms ease-in-out',
      backgroundColor: theme.palette.primary.main,
    },
    [`& .${classes.menuNav}`]: {
      transform: 'translate(0,0)',
      opacity: 0,
      transition: 'opacity 0.4s ease-in',
    },
  },

  [`&.${classes.closed}`]: {
    [`& .${classes.menu}`]: {
      backgroundColor: theme.palette.button.mainHover,
    },
    [`& .${classes.menuNav}`]: {
      transform: 'translate(0,40px)',
      opacity: 0,
    },
  },
}))

type Subnavigation = {
  title: string
  type: 'subnavigation'
  mainPage: Link
  pages: NavItem[]
}

type Link = {
  title: string
  type: 'link'
  href: string
  isExternal?: any
}

type NavItem = Link | Subnavigation

export type HeaderProps = {
  pageContext: DBN.PageHelpers.PageContext
  navItems: Array<NavItem>
  hide?: boolean
}

export default function Header({
  pageContext,
  navItems,
  hide,
}: HeaderProps): ReactElement {
  const scrollTrigger = useScrollTrigger({ threshold: 0 })
  const [menuState, setMenuState] = useState<
    'opening' | 'opened' | 'closing' | 'closed'
  >('closed')
  const [currentSubmenu, setCurrentSubmenu] = useState<string>('')
  const location = useLocation()
  const { currentTheme } = useModuleTheme()
  const { setCursorType } = useCustomCursor()
  const { getText } = useGlobalText()
  const homeLink =
    pageContext.locale && pageContext.locale !== defaultLang
      ? `/${pageContext.locale}/`
      : '/'

  const theme = useTheme()
  const greaterThanXl = useMediaQuery(theme.breakpoints.up('xl'))
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'))

  const getClipPath = () => {
    if (typeof window === 'undefined') return `circle(0vw)`

    if (['opening', 'opened'].includes(menuState)) {
      return `circle(${Math.sqrt(
        Math.pow(window.innerWidth / 2, 2) +
          Math.pow(window.innerHeight / 2, 2),
      )}px at 50% 50%)`
    } else {
      if (greaterThanXl) {
        return `circle(0vw at calc(${
          window.innerWidth - 36
        }px - ${theme.spacing(12)}) 36px)`
      } else if (greaterThanMd) {
        return `circle(0vw at calc(${
          window.innerWidth - 36
        }px - ${theme.spacing(6)}) 36px)`
      } else {
        return `circle(0vw at ${window.innerWidth - 36}px 36px)`
      }
    }
  }

  const getSubnavClipPath = () => {
    if (typeof document === 'undefined') return ''

    if (currentSubmenu) {
      if (greaterThanMd) {
        return `circle(calc(20vw + 36vh) at 57% 50%)`
      } else {
        return `circle(73vh at 32% 16%)`
      }
    } else {
      return `circle(0vw at ${document.documentElement.style.getPropertyValue(
        '--x',
      )} ${document.documentElement.style.getPropertyValue('--y')})`
    }
  }

  const openMenu = () => {
    document.body.style.overflow = 'hidden'
    setMenuState('opening')
    setTimeout(() => setMenuState('opened'), 1100)
  }

  const closeMenu = () => {
    document.body.style.overflow = ''
    setMenuState('closing')
    setTimeout(() => setMenuState('closed'), 550)
  }

  const delayCloseMenu = (timeout = 550) => {
    document.body.style.overflow = ''
    setTimeout(() => setMenuState('closed'), timeout)
  }

  const toggleMenu = () => {
    switch (menuState) {
      case 'opened':
        closeMenu()
        break
      case 'closed':
        openMenu()
        break
    }
  }

  useEffect(() => {
    if (menuState === 'closed') setCurrentSubmenu('')

    const listener = globalHistory.listen((action) => {
      if (!(menuState == 'opened' || menuState == 'opening')) return
      if (location.pathname != action.location.pathname) {
        delayCloseMenu(550)
      } else {
        closeMenu()
      }
    })
    return () => {
      listener()
    }
  }, [menuState])

  const generateNavItems = (items: NavItem[]) => {
    return items?.map((item, i) => {
      if (item.type === 'link' && !item.isExternal) {
        return (
          <GatsbyLink
            key={i}
            className={clsx(classes.menuNavLink, {
              active: item.href
                ? location.pathname.startsWith(item.href)
                : false,
            })}
            to={item.href}
            onMouseEnter={() => setCursorType('link')}
            onMouseLeave={() => setCursorType('')}
            onClick={() => setCursorType('')}
          >
            <span>{item.title}</span>
          </GatsbyLink>
        )
      } else if (item.type === 'subnavigation') {
        return (
          <button
            key={i}
            className={clsx(classes.menuNavLink, {
              active: item.title === currentSubmenu,
            })}
            onMouseEnter={() => setCursorType('link')}
            onMouseLeave={() => setCursorType('')}
            onClick={() => {
              setCursorType('')
              setCurrentSubmenu(item.title)
            }}
          >
            <span>{item.title}</span>
            <div
              className={classes.menuNavLinkIconContainer}
              onMouseEnter={() => setCursorType('hide')}
              onMouseLeave={() => setCursorType('link')}
            >
              <Icon
                name="ArrowRight"
                size="inherit"
                color="inherit"
                className={clsx(classes.menuNavLinkIcon)}
              />
            </div>
          </button>
        )
      } else {
        console.error('external links not supported in header menu.')
      }
    })
  }

  return (
    <Root
      className={clsx(classes.headerRoot, currentTheme, {
        [classes.headerHide]: ['opened', 'opening', 'closing'].includes(
          menuState,
        )
          ? false
          : scrollTrigger || hide,
        [classes[menuState]]: menuState,
      })}
    >
      <Grid container alignItems="stretch">
        <Grid container size={{ xs: 6 }}>
          <GatsbyLink
            className={classes.headerLink}
            to={homeLink}
            onMouseEnter={() => setCursorType('hide')}
            onMouseLeave={() => setCursorType('')}
            title={getText('navigation.home.label')}
          >
            <Icon name="DBNN" className={classes.headerLogoImage} />
          </GatsbyLink>
        </Grid>
        <Grid
          container
          size={{ xs: 6 }}
          className={clsx(classes.headerBurger)}
          justifyContent="flex-end"
        >
          <button
            className={clsx(classes.headerBurgerButton)}
            onClick={toggleMenu}
            onMouseEnter={() => setCursorType('hide')}
            onMouseLeave={() => setCursorType('')}
            title={getText('navigation.menu.label')}
          >
            <span></span>
            <span></span>
          </button>
        </Grid>
      </Grid>
      <div
        className={classes.menu}
        style={{ '--clip-path': getClipPath() } as React.CSSProperties}
      >
        <div className={clsx(classes.menuNav, currentSubmenu ? 'hide' : '')}>
          {generateNavItems(navItems)}
        </div>
        {(() => {
          const subnavigation: Subnavigation | undefined = navItems.find(
            (item) =>
              item.type === 'subnavigation' && item.title === currentSubmenu,
          ) as Subnavigation
          return (
            <>
              <button
                className={clsx(
                  classes.menuNavLink,
                  classes.menuSubnavClose,
                  currentSubmenu ? '' : 'hide',
                )}
                onMouseEnter={() => setCursorType('link')}
                onMouseLeave={() => setCursorType('')}
                onClick={() => {
                  setCursorType('')
                  setCurrentSubmenu('')
                }}
              >
                <div
                  className={clsx(classes.menuNavLinkIconContainer, 'back')}
                  onMouseEnter={() => setCursorType('hide')}
                  onMouseLeave={() => setCursorType('link')}
                >
                  <Icon
                    name="ArrowRight"
                    size="inherit"
                    color="inherit"
                    className={clsx(classes.menuNavLinkIcon)}
                  />
                </div>
                <span>{subnavigation ? subnavigation.title : ''}</span>
              </button>
              <div
                className={clsx(
                  classes.menuSubnav,
                  currentSubmenu ? 'open' : 'closed',
                )}
                style={
                  { '--clip-path': getSubnavClipPath() } as React.CSSProperties
                }
              >
                <div className={classes.menuSubnavItems}>
                  {subnavigation?.mainPage?.title &&
                    subnavigation?.mainPage?.href && (
                      <GatsbyLink
                        className={clsx(classes.menuNavLink, 'mainPage', {
                          active: subnavigation.mainPage.href
                            ? location.pathname.startsWith(
                                subnavigation.mainPage.href,
                              )
                            : false,
                        })}
                        to={subnavigation.mainPage.href}
                        onMouseEnter={() => setCursorType('link')}
                        onMouseLeave={() => setCursorType('')}
                        onClick={() => setCursorType('')}
                      >
                        <span>{subnavigation.mainPage.title}</span>
                        <MenuHighlight className="highlight" />
                      </GatsbyLink>
                    )}
                  {subnavigation && generateNavItems(subnavigation.pages)}
                </div>
              </div>
            </>
          )
        })()}
        {/* <div>
          {pageContext.page?.languages && (
            <div className={clsx(classes.menuLang)}>
              {Object.keys(pageContext.page.languages).map((locale: string) => (
                <Link
                  className={classes.langLink}
                  to={pageContext.page.languages[locale]}
                  isExternal={false}
                  key={locale}
                >
                  {locale}
                </Link>
              ))}
            </div>
          )}
        </div> */}
      </div>
    </Root>
  )
}
