import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import { Grid2 as Grid, Typography } from '@mui/material'

import useGlobalText from '@system/hooks/useGlobalText'
import Paragraph from '@components/text/paragraph'
import SocialIcon from '@components/core/socialicon'
import Link from '@components/core/link'

const PREFIX = 'index'

const classes = {
  footerRoot: `${PREFIX}-footerRoot`,
  footerCopy: `${PREFIX}-footerCopy`,
  copyLink: `${PREFIX}-copyLink`,
  footerNav: `${PREFIX}-footerNav`,
  copyright: `${PREFIX}-copyright`,
  nav: `${PREFIX}-nav`,
  navLink: `${PREFIX}-navLink`,
  social: `${PREFIX}-social`,
  socialIcon: `${PREFIX}-socialIcon`,
}

const Root = styled('footer')(({ theme }) => ({
  [`&.${classes.footerRoot}`]: {
    position: 'relative',
    color: theme.palette.text.invert,
    background: theme.palette.secondary.main,
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    zIndex: 40,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(12),
      paddingRight: theme.spacing(10),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(16),
      paddingLeft: theme.spacing(16),
    },
  },

  [`& .${classes.footerCopy}`]: {
    marginBottom: theme.spacing(14),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },

  [`& .${classes.copyLink}`]: {
    color: theme.palette.text.tertiary,
    '& > span::after': {
      bottom: '4px',
    },
    '&:hover': {
      color: 'inherit',
    },
  },

  [`& .${classes.footerNav}`]: {
    alignItems: 'flex-start',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },

  [`& .${classes.copyright}`]: {
    ...theme.typography.footernavigation,
    marginTop: theme.spacing(8),
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
    },
  },

  [`& .${classes.nav}`]: {
    ...theme.typography.footernavigation,
    flex: '1 0 auto',
  },

  [`& .${classes.navLink}`]: {
    display: 'block',
    marginBottom: theme.spacing(8),
    color: 'inherit',
    [theme.breakpoints.up('lg')]: {
      display: 'inline-block',
      marginBottom: 0,
      marginLeft: theme.spacing(10),
    },
    '& > span::after': {
      bottom: '-3px',
    },
  },

  [`& .${classes.social}`]: {
    textAlign: 'right',
    marginBottom: theme.spacing(16),
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },

  [`& .${classes.socialIcon}`]: {
    display: 'inline-block',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(8),
    },
  },
}))

export type FooterProps = {
  navItems: Array<{
    title: string
    href: string
    isExternal?: any
  }>
}

export default function Footer({ navItems }: FooterProps): ReactElement {
  const { getText } = useGlobalText()

  return (
    <Root className={classes.footerRoot}>
      <div className={classes.footerCopy}>
        <Typography variant="h3" component="p">
          {getText('navigation.copy')}
        </Typography>
        <Paragraph>
          {getText('navigation.email.link') &&
            getText('navigation.email.label') && (
              <Link
                to={getText('navigation.email.link')}
                className={classes.copyLink}
                isExternal={true}
              >
                {getText('navigation.email.label')}
              </Link>
            )}
        </Paragraph>
      </div>
      <Grid
        container
        justifyContent="space-between"
        className={classes.footerNav}
      >
        <Grid component="p" className={classes.copyright}>
          {getText('copyright')}
        </Grid>
        <Grid className={classes.nav}>
          {navItems?.map((item, i) => {
            return (
              <Link
                key={i}
                className={classes.navLink}
                to={item.href}
                isExternal={item.isExternal}
              >
                {item.title}
              </Link>
            )
          })}
        </Grid>
        <Grid className={classes.social}>
          <SocialIcon
            item={{
              name: 'LinkedIn',
              href: getText('social.linkedin.link'),
            }}
            title={getText('social.linkedin.title')}
            className={classes.socialIcon}
          />
          <SocialIcon
            item={{
              name: 'Twitter',
              href: getText('social.twitter.link'),
            }}
            title={getText('social.twitter.title')}
            className={clsx(classes.socialIcon)}
          />
          <SocialIcon
            item={{
              name: 'Instagram',
              href: getText('social.instagram.link'),
            }}
            title={getText('social.instagram.title')}
            className={clsx(classes.socialIcon)}
          />
          <SocialIcon
            item={{
              name: 'Spotify',
              href: getText('social.spotify.link'),
            }}
            title={getText('social.spotify.title')}
            className={clsx(classes.socialIcon)}
          />
        </Grid>
      </Grid>
    </Root>
  )
}
