import React, { ReactElement, Suspense, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import clsx from 'clsx'
import browserLang from 'browser-lang'
import { defaultLang, fallbackLang, availableLang } from '@system/translations'
import { isbot } from 'isbot'
import Header from '@components/modules/global/header'
import Footer from '@components/modules/global/footer'
import MetaTags from '@components/modules/global/metatags'
import PageTransition from '@components/core/pageTransition'

const PREFIX = 'index'

const classes = {
  layoutRoot: `${PREFIX}-layoutRoot`,
  layoutMain: `${PREFIX}-layoutMain`,
  layoutMainHidde: `${PREFIX}-layoutMainHidde`,
  introFallback: `${PREFIX}-introFallback`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.layoutRoot}`]: {
    display: 'flex',
    flexDirection: 'column',
    jusifyContent: 'flex-start',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
    overflow: 'hidden',
  },

  [`& .${classes.layoutMain}`]: {
    flex: '1 0 auto',
  },

  [`& .${classes.layoutMainHidde}`]: {
    opacity: 0,
  },

  [`& .${classes.introFallback}`]: {
    position: 'fixed',
    backgroundColor: theme.palette.secondary.main,
    width: '100vw',
    height: '100vh',
    zIndex: 51,
  },
}))

const IntroAnimation = React.lazy(
  () => import('@components/modules/content/M008-IntroAnimation'),
)

interface INavNodeQuery {
  __typename: 'ContentfulSubnavigation'
  node_locale: string
  titleNavigation?: string
  mainPage?: INavItemQuery
  pages?: Array<INavItemQuery>
}

interface INavItemQuery {
  __typename: 'ContentfulGenericPage' | 'ContentfulExternalPage'
  titleNavigation: string
  fields: {
    fullPath?: string
    isExternal?: boolean
  }
}

export type LayoutProps = {
  children: React.ReactNode
  pageContext: DBN.PageHelpers.PageContext
}

export default function Layout({
  children,
  pageContext,
}: LayoutProps): ReactElement {
  const { mainNav, footerNav } = useStaticQuery(graphql`
    query {
      mainNav: allContentfulNavigation(
        filter: { identifier: { eq: "main-navigation" } }
      ) {
        nodes {
          node_locale
          pages {
            __typename
            ... on ContentfulGenericPage {
              titleNavigation
              fields {
                fullPath
              }
            }
            ... on ContentfulExternalPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
            ... on ContentfulSubnavigation {
              titleNavigation
              mainPage {
                __typename
                titleNavigation
                fields {
                  fullPath
                }
              }
              pages {
                __typename
                ... on ContentfulGenericPage {
                  titleNavigation
                  fields {
                    fullPath
                  }
                }
                ... on ContentfulExternalPage {
                  titleNavigation
                  fields {
                    fullPath
                    isExternal
                  }
                }
              }
            }
          }
        }
      }
      footerNav: allContentfulNavigation(
        filter: { identifier: { eq: "footer-navigation" } }
      ) {
        nodes {
          node_locale
          pages {
            __typename
            ... on ContentfulGenericPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
            ... on ContentfulExternalPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
          }
        }
      }
    }
  `)

  const headerItems = mainNav.nodes
    .find(
      (node: INavNodeQuery) =>
        node.node_locale == (pageContext.locale || defaultLang),
    )
    .pages.filter((element: INavItemQuery | INavNodeQuery) => {
      if (
        element.__typename === 'ContentfulGenericPage' ||
        element.__typename === 'ContentfulExternalPage'
      ) {
        return element.fields?.fullPath
      } else if (element.__typename === 'ContentfulSubnavigation') {
        return element.titleNavigation
      }
    })
    .map((element: INavItemQuery | INavNodeQuery) => {
      if (
        element.__typename === 'ContentfulGenericPage' ||
        element.__typename === 'ContentfulExternalPage'
      ) {
        return {
          title: element.titleNavigation,
          type: 'link',
          href: element.fields?.fullPath,
          isExternal: element.fields?.isExternal,
        }
      } else if (element.__typename === 'ContentfulSubnavigation') {
        return {
          title: element.titleNavigation,
          type: 'subnavigation',
          mainPage: {
            title: element.mainPage?.titleNavigation,
            type: 'link',
            href: element.mainPage?.fields?.fullPath,
            isExternal: element.mainPage?.fields?.isExternal,
          },
          pages: element.pages
            ? element.pages
                .filter((page: INavItemQuery) => {
                  return page.fields?.fullPath
                })
                .map((page: INavItemQuery) => {
                  return {
                    title: page.titleNavigation,
                    type: 'link',
                    href: page.fields?.fullPath,
                    isExternal: page.fields?.isExternal,
                  }
                })
            : [],
        }
      }
    })

  const footerItems = footerNav.nodes
    ?.find((node: INavNodeQuery) => {
      return node.node_locale === (pageContext.locale || defaultLang)
    })
    .pages?.filter((page: INavItemQuery) => {
      return page.fields?.fullPath
    })
    .map((page: INavItemQuery) => {
      return {
        title: page.titleNavigation,
        href: page.fields?.fullPath,
        isExternal: page.fields?.isExternal,
      }
    })

  const [sffIntro, setSffIntro] = useState(false)
  const [sffIntroBefore, setSffIntroBefore] = useState(true)

  useEffect(() => {
    if (sessionStorage.getItem('sff-intro') !== 'ok') {
      if (navigator.userAgent && !isbot(navigator.userAgent)) {
        if (pageContext?.url == '/') {
          if (pageContext?.locale && pageContext?.page?.languages) {
            const userLang = browserLang({
              languages: availableLang,
              fallback: fallbackLang,
            })
            if (userLang !== (pageContext.locale || defaultLang)) {
              navigate(pageContext.page.languages[userLang])
            }
          }
        }
      }
      setSffIntro(true)
    } else {
      setSffIntroBefore(false)
    }
  }, [setSffIntro])

  const onCompleteHandler = () => {
    window.sessionStorage.setItem('sff-intro', 'ok')
    setSffIntro(false)
    setSffIntroBefore(false)
  }

  const onBeforeCompleteHandler = () => {
    setSffIntroBefore(false)
  }

  return (
    <Root className={clsx(classes.layoutRoot)}>
      <MetaTags
        locale={pageContext.locale}
        meta={{ canonical: pageContext.canonical }}
      />
      <Header
        pageContext={pageContext}
        navItems={headerItems}
        hide={sffIntro || sffIntroBefore}
      />
      {sffIntro && (
        <Suspense fallback={<div className={classes.introFallback} />}>
          <IntroAnimation
            onBeforeComplete={onBeforeCompleteHandler}
            onComplete={onCompleteHandler}
          />
        </Suspense>
      )}
      <main
        className={clsx(classes.layoutMain, {
          [classes.layoutMainHidde]: sffIntroBefore,
        })}
      >
        <PageTransition>{children}</PageTransition>
      </main>
      <Footer navItems={footerItems} />
    </Root>
  )
}
